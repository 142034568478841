import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';
import BaseTopNav from '@/components/base/baseTopNav';
import BaseUpload from '@/components/base/baseUpload';
import { toast } from '@/layouts/components/ToastMessage';
import { rechargeFiatCurrencyApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { FormatUtils } from '@/utils/format';
import { Button } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import QRCode from 'qrcode.react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const RechargePage = () => {
  const { user } = useModel('user');
  const [transactionPassword, setTransactionPassword] = useState(user?.account);
  const [info, setInfo] = useState('');

  const { t } = useTranslation();

  const { run: onSubmit, loading: submitLoading } = useReq(
    rechargeFiatCurrencyApi,
    {
      loadingDefault: false,
      manual: true,
      onSuccess: () => {
        toast.success('操作成功');
        // history.push(PageEnum.MYPAGE);
      },
    },
  );

  return (
    <div className="py-4 bg-gray-100">
      <BaseTopNav title={'XRP' + t('充值')} />

      {/* 充值金额 */}
      <div className="p-4 rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <span className="text-base">充值地址</span>
          {/*<span className="text-green-500 font-bold text-xl">2000</span>*/}
        </div>

        {/* QR Code */}
        <div className="flex justify-center my-4">
          <div className="p-2">
            <QRCode value={''} style={{ width: '200px', height: '200px' }} />
          </div>
        </div>

        {/* 收款地址 */}
        <div className="mb-4">
          <label className="text-gray-500 block mb-2">收款地址</label>
          <div className="flex items-center bg-gray-100 rounded px-4 py-2">
            <span className="flex-grow text-gray-600 truncate">
              TMnpJhtvk7oqt6Gr1HQoYX6fFPgPYeomyT
            </span>
            {/*<button className="text-green-500 ml-2">复制地址</button>*/}

            <div
              className="flex items-center pl-4 gap-1 text-foreground py-[2px]"
              onClick={() => {
                FormatUtils.copyText('12121212');
              }}
            >
              <span className=" flex-shrink-0">{t('复制')}</span>
              <PhCopyFill className="text-[30px]" />
            </div>
          </div>
        </div>

        {/* 交易哈希 */}
        <div className="mb-4">
          <label className="text-gray-500 block mb-2">用户ID</label>
          <div className=" bg-background rounded-md overflow-hidden mt-2 mb-3 flex space-x-2 items-center px-4  h-[40px]">
            {transactionPassword}

            <div
              className="flex items-center pl-4 gap-1 text-foreground py-[2px]"
              onClick={() => {
                FormatUtils.copyText('12121212');
              }}
            >
              <span className=" flex-shrink-0">{t('复制')}</span>
              <PhCopyFill className="text-[20px]" />
            </div>
          </div>
        </div>

        {/* 充值凭证 */}
        <div className="mt-3">
          <label className="text-gray-500 block mb-2">充值凭证</label>
          <div className="border-dashed border-2 border-borderColor rounded-lg text-center text-gray-400">
            <BaseUpload
              minHeight={120}
              currentUrls={info}
              onChange={(url: any) => {
                setInfo(url);
              }}
            >
              <div className="bg-backgroundAuxiliaryColor h-[120px] w-full flex justify-center items-center">
                <span className="text-base">上传凭证</span>
              </div>
            </BaseUpload>
          </div>
        </div>

        {/* 提交按钮 */}
        <div className="mt-5">
          <Button
            className="w-full mainColorButton !h-[35px]"
            spinner={<LoadingSvg />}
            isLoading={submitLoading}
            onClick={() =>
              onSubmit({
                walletType: 2,
                info,
                // id: user.,
              })
            }
          >
            提交
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RechargePage;
